// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colors-debug-white": "#f2f2f2",
	"colors-debug-black": "#1c1c1c",
	"colors-debug-grey": "#767676",
	"colors-debug-light-grey": "#e6e6e6",
	"colors-debug-blue": "#00fbff",
	"colors-debug-purple": "#f708ff",
	"colors-debug-red": "#ff3a17",
	"easings-snap": "cubic-bezier(0,1,.5,1)",
	"easings-ease-out-cubic": "cubic-bezier(.215,.61,.355,1)",
	"easings-ease-in-out-cubic": "cubic-bezier(.645,.045,.355,1)",
	"easings-ease-in-circ": "cubic-bezier(.6,.04,.98,.335)",
	"easings-ease-out-circ": "cubic-bezier(.075,.82,.165,1)",
	"easings-ease-in-out-circ": "cubic-bezier(.785,.135,.15,.86)",
	"easings-ease-in-expo": "cubic-bezier(.95,.05,.795,.035)",
	"easings-ease-out-expo": "cubic-bezier(.19,1,.22,1)",
	"easings-ease-in-out-expo": "cubic-bezier(1,0,0,1)",
	"easings-ease-in-quad": "cubic-bezier(.55,.085,.68,.53)",
	"easings-ease-out-quad": "cubic-bezier(.25,.46,.45,.94)",
	"easings-ease-in-out-quad": "cubic-bezier(.455,.03,.515,.955)",
	"easings-ease-in-quart": "cubic-bezier(.895,.03,.685,.22)",
	"easings-ease-out-quart": "cubic-bezier(.165,.84,.44,1)",
	"easings-ease-in-out-quart": "cubic-bezier(.77,0,.175,1)",
	"easings-ease-in-quint": "cubic-bezier(.755,.05,.855,.06)",
	"easings-ease-out-quint": "cubic-bezier(.23,1,.32,1)",
	"easings-ease-in-out-quint": "cubic-bezier(.86,0,.07,1)",
	"easings-ease-in-sine": "cubic-bezier(.47,0,.745,.715)",
	"easings-ease-out-sine": "cubic-bezier(.39,.575,.565,1)",
	"easings-ease-in-out-sine": "cubic-bezier(.445,.05,.55,.95)",
	"easings-ease-in-back": "cubic-bezier(.6,-.28,.735,.045)",
	"easings-ease-out-back": "cubic-bezier(.175,.885,.32,1.275)",
	"easings-ease-in-out-back": "cubic-bezier(.68,-.55,.265,1.55)",
	"themesettings-columns": "36",
	"themesettings-mob-columns": "21",
	"themecolors-white": "#f2f2f2",
	"themecolors-black": "#1c1c1c",
	"themecolors-faded-black": "rgba(0,0,0,.4)",
	"themecolors-ultra-faded-black": "rgba(0,0,0,.025)",
	"themecolors-asp-black": "#100f10",
	"themecolors-asp-white": "#eff0ef",
	"themevariables-timing": ".25s",
	"themevariables-timing-fast": ".125s",
	"themevariables-timing-slow": ".5s",
	"themevariables-timing-slower": "1s",
	"themevariables-timing-slowest": "2s",
	"themevariables-easing": "cubic-bezier(.785,.135,.15,.86)",
	"themevariables-easing-alt": "cubic-bezier(.455,.03,.515,.955)",
	"themevariables-stroke": "2px",
	"themevariables-header-height": "80px"
};
export default ___CSS_LOADER_EXPORT___;
