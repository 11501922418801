
import { Vue, Component, Prop } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { createModifierClass } from "_utils";
import { ThemeLink, ThemeLinkLogic } from "_types";
import { LinkLogic } from "../components";

@Component({
  name: "CTAButton",
  components: { LinkLogic, SmartText, SmartSVG },
})
export default class CTAButton extends Vue {
  @Prop({ default: "light" }) mode: "light" | "dark";
  @Prop({ type: String }) label: string;
  @Prop({ default: false, type: Boolean }) alwaysShowIcon: boolean;
  @Prop({ default: true, type: Boolean }) showIcon: boolean;
  @Prop({ default: false, type: Boolean }) secondary: boolean;
  @Prop({ default: SVGLibraryCustom.ASPArrowRight }) icon: string;
  @Prop() link: ThemeLink | ThemeLinkLogic;
  @Prop({ default: true, type: Boolean }) bold: boolean;

  rootClass = "c-cta-button";
  SVGLibraryCustom = SVGLibraryCustom;

  click() {
    this.$emit("click");
  }

  get classes() {
    return [
      this.rootClass,
      `${this.rootClass}--${this.mode}`,
      ...createModifierClass(this.rootClass, this.alwaysShowIcon, "alwaysShowIcon"),
      ...createModifierClass(this.rootClass, this.secondary, "secondary"),
    ];
  }
}
