
import { Locale } from "_core";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ThemeLink } from "_types";
import { generateID, createModifierClass } from "_utils";
import { SmartSVG, SVGLibraryCustom } from "_components";
import NavigationButton from "../NavigationButton/index.vue";

@Component({
  name: "HeaderMenu",
  components: { NavigationButton, SmartSVG },
})
export default class HeaderMenu extends Vue {
  @Prop() links: { link: ThemeLink }[];
  @Prop({ default: false, type: Boolean }) isOpen: boolean;

  rootClass = "c-header-menu";
  SVGLibraryCustom = SVGLibraryCustom;

  close() {
    this.$emit("close");
  }

  get ID() {
    return generateID();
  }

  get classes() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isOpen, "is-open"),
    ];
  }

  get languages() {
    return Locale.availableLanguages
      ? Locale.availableLanguages.filter(
          (lang) => lang.locale !== Locale.currentLocale
        )
      : [];
  }

  switchLocale(locale: string) {
    Locale.switchLocale(locale, this.$route);
  }
}
