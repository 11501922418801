// Add all components for theme here (will be accessible from "_components")
// If theres a chance a component will not be loaded, it should be lazy loaded

// Base
export { default as LinkLogic } from './LinkLogic/index.vue';
// export { default as Button } from './Button/index.vue';

// export { default as HeroImage } from "./HeroImage/index.vue";
export const HeroImage = () => import("./HeroImage/index.vue");
export { default as CTAButton } from "./CTAButton/index.vue";
// export { default as Chip } from "./Chip/index.vue";
export const Chip = () => import("./Chip/index.vue");

// export { default as Carousel } from "./Carousel/index.vue";
export const Carousel = () => import("./Carousel/index.vue");
export { default as PageTitle } from './PageTitle/index.vue';
// export const PageTitle = () => import("./PageTitle/index.vue");

export { default as LinkButton } from "./LinkButton/index.vue";

export { default as Header } from "./Header/index.vue";
export { default as Footer } from "./Footer/index.vue";
export const DynamicContent = () => import("./DynamicContent/index.vue");

// export { default as DynamicContent } from "./DynamicContent/index.vue";
