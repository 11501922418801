/*
    Add SVGs to be accessible to CL and SmartSVG by simply
    adding it to the ./library folder and adding an enum
    matching the file name below.
*/

export enum SVGLibraryCustom {
  BoilerplateBackButton = "boilerplate-back-button",
  BoilerplateClose = "boilerplate-close",
  BoilerplateCopy = "boilerplate-copy",
  BoilerplateError = "boilerplate-error",
  LhLogo = "lh-logo",
  ASPArrowRight = "asp-arrow-right",
  ASPArrowLeft = "asp-arrow-left",
  ASPAvatar = "asp-avatar",
  ASPLogo = "asp-logo",
  ASPLogoHorizontal = "asp-logo-horizontal",
  ASPFacebook = "asp-facebook",
  ASPInstagram = "asp-instagram",
  ASPTwitter = "asp-twitter",
  ASPLinkedin = "asp-linkedin",
  ASPChevron = "asp-chevron",
  ASPEmblem = 'asp-emblem',
  ASPSlider = 'asp-slider',
  ASPClose = 'asp-close',
  SBIcons = 'sb-icons',
  ASPFlagCS = 'asp-flag-cz',
  ASPFlagEN = 'asp-flag-uk'
}
