import { Vue, Component } from "vue-property-decorator";

/**
 * Adds listener for viewport entering/exiting.
 * @function onResizeElement(rect): void
 * @static rect
 */
@Component
export class onResizeElementMixin extends Vue {
    private $elementObserver: ResizeObserver;
    public rect = null;

    /**
     * Call onResizeElement(rect: object) to detect element manipulation
     * */
    public onResizeElement(rect): void { }

    private onResizeElementHandler(rect): void {
        this.rect = rect;
        this.onResizeElement(rect);
    }

    private setup(on: boolean): void {
        if (this.$el.nodeType === 1 && this.$elementObserver) {
            on
                ? this.$elementObserver.observe(this.$el)
                : this.$elementObserver.unobserve(this.$el);
        }
    }

    mounted() {
        this.setup(true);
    }
    beforeDestroy() {
        this.setup(false);
    }
}
