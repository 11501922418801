// import "url-polyfill";
import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import { PasswordProtectorPage } from "_components";
import { Routes } from "@app/routes";
import { EventBus, EventBusChannels, PasswordProtector } from "_core";

Vue.use(Router);
Vue.use(Meta);

// @ts-ignore
const baseUrl = new URL(window.BASE_URL).pathname;

export const router = new Router({
  routes: [{
    path: "/guard",
    name: "site-guard",
    component: PasswordProtectorPage,
  }, ...Routes],
  mode: "history",
  base: baseUrl,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        EventBus.$emit('anim:page')
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
});

PasswordProtector.setupPasswordProtection(router);