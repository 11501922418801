import { Mixins, Component } from "vue-property-decorator";
import { onScroll, onView, onResizeElement } from './';

/**
 * Adds listener for firing animation when element enters viewport.
 * @function animateElements(): void
 */
@Component
export class handleOnViewAnimationMixin extends Mixins(onScroll, onView, onResizeElement) {
    private hasAnimated: boolean = false;
    private ready: boolean = false;
    private top: number = 0;

    /**
 * Call animateElements() to listen for element entering viewport from above
 * */
    public animateElements(): void { }

    onResizeElement() {
        this.top = (this.$el as HTMLElement).offsetTop - window.innerHeight;
        this.ready = true;
    }

    onScroll(scroll: number) {
        if (!this.ready || !this.hasAnimated) return;
        else if (scroll < this.top) this.hasAnimated = false;
    }

    onView(inView: boolean) {
        if (!this.ready) return;

        if (inView && !this.hasAnimated) {
            this.animateElements();
            this.hasAnimated = true;
        }
    }
}
