
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { onScroll, onResize } from "_mixins";
import { EventBus, EventBusChannels, Locale } from "_core";
import { createModifierClass, generateID } from "_utils";
import NavigationButton from "./NavigationButton/index.vue";
import Menu from "./Menu/index.vue";
import { CTAButton } from "../components";

import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";

import {
  MainSettings,
  RouterPageNames,
  THEME_LINK_TYPE,
  ThemeLink,
} from "_types";

@Component({
  components: { Menu, CTAButton, SmartText, NavigationButton, SmartSVG },
})
export default class Header extends Mixins(onScroll, onResize) {
  @Prop({ required: true }) settings: MainSettings;

  rootClass = "c-header";
  isFixed = false;
  logo = SVGLibraryCustom.ASPLogoHorizontal;
  burgerMenu = SVGLibraryCustom.ASPSlider;
  arrowIcon = SVGLibraryCustom.ASPArrowLeft;

  isOpen = false;

  mounted() {
    EventBus.$on(EventBusChannels.ChangingPage, () => {
      if (this.isOpen) this.isOpen = false;
    });
  }

  onResize() {
    if (this.isOpen) this.isOpen = false;
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  toggleBack() {
    if (this.$route.path !== "/") {
      let pathArray = this.$route.path.split("/");
      // remove the empty elements at the start and the end of the array
      pathArray = pathArray.filter((path) => path);
      // remove the last part of the array
      pathArray.pop();
      // create the new path
      let newPath = "/" + pathArray.join("/");
      // navigate to the new path
      this.$router.push(newPath);
    }
  }

  onScroll(scroll: number) {
    this.isFixed = scroll > 0;
  }

  get isHomePage() {
    return this.$route.path === "/";
  }

  get ID() {
    return generateID();
  }

  get links() {
    return this.settings.header && this.settings.header.header_links;
  }

  get languages() {
    return Locale.availableLanguages
      ? Locale.availableLanguages.filter(
          (lang) => lang.locale !== Locale.currentLocale
        )
      : [];
  }

  get enableLangSelector() {
    return Locale.isEnabled;
  }

  get getClasses() {
    return [
      this.rootClass,
      ...createModifierClass(
        this.rootClass,
        this.isFixed || this.isOpen,
        "fixed"
      ),
      ...createModifierClass(
        this.rootClass,
        this.$route.name === RouterPageNames.HOME,
        "is-home"
      ),
    ];
  }

  switchLocale(locale: string) {
    Locale.switchLocale(locale, this.$route);
  }
}
