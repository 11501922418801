
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { SmartText, SmartSVG, SVGLibraryCustom } from "_components";
import { createModifierClass } from "_utils";
import { ThemeLink, ThemeLinkLogic } from "_types";
import { LinkLogic } from "../../components";

@Component({
  name: "NavigationButton",
  components: { LinkLogic, SmartText, SmartSVG },
})
export default class NavigationButton extends Vue {
  @Ref() linkLogic: LinkLogic;
  @Prop({ type: String }) label: string;
  @Prop() link: ThemeLink | ThemeLinkLogic;

  rootClass = "c-cta-nav-button";
  SVGLibraryCustom = SVGLibraryCustom;
  isMounted = false;

  mounted() {
    this.isMounted = true;
  }

  click() {
    this.$emit("click");
  }

  get classes() {
    return [
      this.rootClass,
      ...createModifierClass(this.rootClass, this.isCurrentPage, "selected"),
    ];
  }

  get isCurrentPage() {
    if (!this.isMounted) return false;
    return this.linkLogic && this.linkLogic.isOnCurrentPage;
  }
}
