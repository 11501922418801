import { FontSettingsType } from "_types";

export const fonts: FontSettingsType = {
  /* 
    Define font classes here and in styles/01_init/_typography.scss
    - Enable custom fonts by adding @font-face to src/public/fonts/fonts.css
      and enabling 'Custom font' within CMS global settings
    - They will automatically be usable in SmartText + automatically add themselves to docs
  */

  //  CSS Class names and appropriate HTML tag
  fontClasses: {
    hero: [
      { name: "hero-title", tag: "h1" },
      { name: "hero-H1", tag: "h1" },
      { name: "hero-H2", tag: "h2" },
      { name: "hero-p", tag: "p" },
      { name: "hero-p-small", tag: "p" },
      { name: "hero-button", tag: "p" },
    ],
    body: [
      { name: "body-copy", tag: "p" },
      { name: "body-subheading", tag: "h3" },
    ],
    alt: [],
  },

  //  Custom font name per category of text (as string)
  customFonts: {
    hero_font: null,
    body_font: null,
    alt_font: null,
  },
};
