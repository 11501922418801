
import { Mixins, Component, Prop, Ref } from "vue-property-decorator";
import { FooterSettings, ThemeLink, THEME_LINK_TYPE } from "_types";
import { generateID } from "_utils";
import { handleOnViewAnimation } from "_mixins";
import { GSAP } from "_core";

import LinkButton from "../LinkButton/index.vue";
import CTAButton from "../CTAButton/index.vue";
import WebGL from "./WebGL.vue";

import {
  SmartText,
  WYSIWYG,
  SmartSVG,
  SVGLibraryCustom,
  SmartImage,
  SmartImageProps,
} from "_components";

@Component({
  name: "Footer",
  components: {
    CTAButton,
    SmartText,
    SmartSVG,
    WYSIWYG,
    LinkButton,
    SmartImage,
    WebGL,
  },
})
export default class Footer extends Mixins(handleOnViewAnimation) {
  @Prop({ required: true }) settings: FooterSettings;
  @Ref() titleRef: SmartText;
  @Ref() ctaButtonRef: HTMLElement;

  @Ref() textRef: SmartText;
  @Ref() phoneRef: LinkButton;
  @Ref() emailRef: LinkButton;
  @Ref() bottomRef: HTMLElement;

  rootClass = "c-footer";
  SVGLibraryCustom = SVGLibraryCustom;
  private tl: gsap.core.Timeline;

  isReady = false;

  mounted() {
    setTimeout(() => {
      this.isReady = true;
    }, 100); // hotfix until smartimagev2
  }

  get enable() {
    return this.settings.enable;
  }

  get uniqueID() {
    return generateID();
  }

  get title() {
    return this.settings.contact_section.title;
  }

  get image(): SmartImageProps {
    return {
      image: this.settings.contact_section.image,
      fit: "cover",
      lazyload: true,
    };
  }

  get emailAddress() {
    return this.settings.contact_section.email_address;
  }

  get address() {
    return this.settings.contact_section.address;
  }

  get phone() {
    return this.settings.contact_section.phone;
  }

  get phoneLink() {
    return {
      label: this.settings.contact_section.phone,
      type: THEME_LINK_TYPE.EXTERNAL_URL,
      external_url: `tel:${this.phone.replace(/ /g, "")}`,
    };
  }

  get emailLink() {
    return {
      label: this.emailAddress,
      type: THEME_LINK_TYPE.EXTERNAL_URL,
      external_url: `mailto:${this.emailAddress}`,
    };
  }

  get buttonLink() {
    return {
      label: this.settings.contact_section.button_label,
      type: THEME_LINK_TYPE.EXTERNAL_URL,
      external_url: `mailto:${this.emailAddress}`,
    };
  }

  get socials(): { link: ThemeLink[] } & any {
    return (
      this.enable &&
      this.settings &&
      this.settings.social_media.map((link) => ({
        link: {
          label: "",
          type: THEME_LINK_TYPE.EXTERNAL_URL,
          external_url: link.link,
        },
        type: link.type,
      }))
    );
  }

  get links() {
    return this.enable && this.settings.links;
  }

  get legalLinks() {
    return this.enable && this.settings.legal_links;
  }

  get copyrightText() {
    return this.enable && this.settings.copyright_text;
  }

  animateElements() {
    if (this.tl) this.onComplete();

    const targets: HTMLElement[] = [];

    if (this.titleRef) targets.push(this.titleRef.$el as HTMLElement);
    if (this.ctaButtonRef) targets.push(this.ctaButtonRef);
    if (this.textRef) targets.push(this.textRef.$el as HTMLElement);
    if (this.phoneRef) targets.push(this.phoneRef.$el as HTMLElement);
    if (this.emailRef) targets.push(this.emailRef.$el as HTMLElement);
    if (this.bottomRef) targets.push(this.bottomRef);

    if (targets.length) {
      this.tl = GSAP.timeline();
      this.tl.set(targets, { y: 30, opacity: 0 });
      this.tl.fromTo(
        targets,
        { y: 30, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.5,
          stagger: 0.15,
          onComplete: this.onComplete,
        }
      );
    }
  }

  onComplete() {
    if (this.tl) this.tl.kill();
  }
}
