import { Component, Mixins } from "vue-property-decorator";
import { lerp, mapAnimation } from '_utils';
import { config } from "_core";
import { onScroll, onView } from './';

export type handleAnimationOptions = {
    target: HTMLElement,
    points: {
        scroll: number,
        x?: number,
        y?: number,
        scale?: number,
        rotate?: number,
        opacity?: number,
        custom?: {
            name: string,
            value: number,
            type?: string,
        }[]
    }[],
    options?: {
        lerp?: boolean,
        inViewOnly?: boolean,
        callback?: (progress: number) => void;
    }
}[];

export type handleAnimationResponsiveOptions = {
    desktop: handleAnimationOptions;
    mobile: handleAnimationOptions;
}

@Component
export class handleAnimationMixin extends Mixins(onScroll, onView) {
    private animations: handleAnimationOptions = [];
    private currentTransforms: {
        x: number, y: number, scale: number, opacity: number, rotate: number
        custom?: Record<string, number>
    }[] = [];


    private calculateAmount(current: any, previous: any, target: any, scroll: number, useLerp: boolean): any {
        const basicProperties = ['x', 'y', 'scale', 'opacity', 'rotate'];
        const result: any = {};

        for (const prop of basicProperties) {
            if (previous[prop] !== undefined && target[prop] !== undefined) {
                const mappedValue = mapAnimation([previous.scroll, target.scroll], [previous[prop] || 0, target[prop] || 0], scroll);
                result[prop] = useLerp ? lerp(current[prop], mappedValue) : mappedValue;
            } else {
                result[prop] = current[prop]; // Preserve current values if target does not define them
            }
        }

        // Handle custom properties
        if (previous.custom && target.custom) {
            result['custom'] = {};
            for (const customProp of target.custom) {
                const prevValue = previous.custom.find(p => p.name === customProp.name)?.value || 0;
                const mappedValue = mapAnimation([previous.scroll, target.scroll], [prevValue, customProp.value], scroll);
                result['custom'][customProp.name] = useLerp ? lerp(current.custom?.[customProp.name] || 0, mappedValue) : mappedValue;
            }
        }

        return result;
    }

    private setAnimationStyle(element: HTMLElement, amount: any) {
        element.style.transform = `translate(${amount.x || 0}px, ${amount.y || 0}px) scale(${amount.scale || 1}) rotate(${amount.rotate || 0}deg) translateZ(0)`;
        element.style.opacity = `${amount.opacity || 1}`;
        // Handle custom properties
        if (amount.custom) {
            for (const propName in amount.custom) {
                if (propName === "filter" && amount.custom[propName]) {
                    element.style[propName] = `grayscale(${amount.custom[propName]})`;
                } else if (propName === "borderRadius" && amount.custom[propName] != null) {
                    element.style[propName] = `${amount.custom[propName]}px`;
                } else {
                    element.style[propName] = amount.custom[propName];
                }
            }
        }

    }

    public _onScroll(scroll: number) { }

    onScroll(scroll: number) {
        if (!this.animations || !this.animations.length) return;

        for (let j = 0; j < this.animations.length; j++) {
            const { points, options: { lerp: useLerp = true, inViewOnly = true, callback = null } = {} } = this.animations[j];
            if (inViewOnly && !this.isInView) return;

            if (callback) {
                // Calculate animation progress
                const totalScrollRange = points[points.length - 1].scroll - points[0].scroll;
                const currentScrollPosition = scroll - points[0].scroll;
                const progress = Math.min(Math.max((currentScrollPosition / totalScrollRange) * 100, 0), 100);

                // Invoke callback with animation progress
                callback(progress);
            }


            let currentPoint = points[0];
            let nextPoint = points[1] || points[0]; // fallback to the first point if there's no second
            for (let i = 1; i < points.length; i++) {
                if (scroll <= points[i].scroll) {
                    currentPoint = points[i - 1];
                    nextPoint = points[i];
                    break;
                }
            }

            const currentTransform = this.currentTransforms[j];
            const amount = this.calculateAmount(currentTransform, currentPoint, nextPoint, scroll, useLerp);

            this.setAnimationStyle(this.animations[j].target, amount);
            this.currentTransforms[j] = amount;
        }
        this._onScroll(scroll);
    }

    private resetAnimationStyles(element: HTMLElement) {
        element.style.transform = '';
        element.style.opacity = '';

        // Reset custom properties
        const animation = this.animations.find(anim => anim.target === element);
        if (animation) {
            const customProps = animation.points.flatMap(point => point.custom || []);
            for (const customProp of customProps) {
                element.style[customProp.name] = ''; // Resetting each custom property
            }
        }
    }

    public setupAnim(animations: handleAnimationOptions | handleAnimationResponsiveOptions, applyToBoth: boolean = false) {
        const isMob = window.innerWidth < config.mobBreakpoint

        // Resetting previous animations
        for (const anim of this.animations) {
            this.resetAnimationStyles(anim.target);
        }
        this.animations = [];

        if ('desktop' in animations && 'mobile' in animations) {
            // Handle handleAnimationResponsiveOptions type
            if (isMob) {
                this.animations = animations.mobile;
            } else {
                this.animations = animations.desktop;
            }
        } else {
            // Handle handleAnimationOptions type
            if (applyToBoth || !isMob) {
                this.animations = animations as handleAnimationOptions;
            }
        }

        this.currentTransforms = Array.from({ length: this.animations.length }, () => ({ x: 0, y: 0, rotate: 0, scale: 1, opacity: 1 }));
    }

}
