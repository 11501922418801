import ProjectConfig from "@app/project-config";
import { fonts as FontSettings } from "@app/fonts";
import { SCSSVariables } from "./modules/scss-variables";

const {
  innerHeight,
  innerWidth,
  BASE_URL,
  THEME_DIRECTORY,
  LANGUAGE_SETTINGS,
  AUTH,
  CACHE_TIMESTAMP,
} = window as any;
const { body, documentElement } = document;
const { enableCaching, options, consoleMessage, enable404Push } = ProjectConfig;

const debug = process.env.NODE_ENV !== "production";

export const config = {
  project: {
    ...ProjectConfig.project,
  },
  boilerplate: {
    name: process.env.BOILERPLATE_NAME,
    version: process.env.BOILERPLATE_VERSION,
  },
  // Dynamically set by browser
  baseUrl: BASE_URL,
  basePath: BASE_URL && new URL(BASE_URL).pathname,
  baseOrigin: BASE_URL && new URL(BASE_URL).origin,
  languageSettings: LANGUAGE_SETTINGS,
  themePath: THEME_DIRECTORY,
  cacheTimestamp: CACHE_TIMESTAMP,
  debug,
  options,
  api: {
    location: "wp-json/wp/v2/sb-api/",
  },
  grid: SCSSVariables.gridSettings,
  enableCaching,
  hasTouchScreen: false, // Dynamically set
  auth: AUTH,

  dom: {
    html: documentElement,
    body: body,
    width: innerWidth,
    height: innerHeight,
    getFullBodyHeight(): number {
      return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        documentElement.clientHeight,
        documentElement.scrollHeight,
        documentElement.offsetHeight
      );
    },
    isMob: false,
  },

  // Mobile should be < not <= / desktop is => this
  mobBreakpoint: 768,

  // Mobile should be < not <= / desktop is => this
  tabletBreakpoint: 1024,

  // Large Desktop should be >= / desktop is < this
  largeDesktopBreakpoint: 1800,

  smartImageConfig: {
    enableWebP: true,
    rootMarginIntersectionObserver: "50% 50% 50% 50%",

    // Hard coded WP sizes (Custom from PHP theme) - Add here when adding to PHP
    imageSizes: [
      "c-thumbnail",
      "c-small",
      "c-medium",
      "c-medium_large",
      "c-large",
      "x-large",
      "xx-large",
      "xxx-large",
      'huge',
      "x-huge",
      "xx-huge",
    ],
  },
  fontSettings: {
    fontsLoaded: false,
    fontsToUse: {
      debug_font: "",
      hero_font: "",
      body_font: "",
      alt_font: "",
    },
    // Use this font if custom is selected in CMS
    backup: {
      ...FontSettings.customFonts,
      debug_font: "Source Code Pro",
    },
    fontClasses: {
      ...FontSettings.fontClasses,
      debug: [
        { name: "debug-copy", tag: "p" },
        { name: "debug-small-headline", tag: "h3" },
        { name: "debug-headline", tag: "h1" },
      ],
    },
    fontSizes: [100, 200, 300, 400, 500, 600, 700, 800],
  },

  enableStaticTheme: ProjectConfig.enableStaticTheme,
  consoleMessage,
  enable404Push,
  isHighPerf: true,
};

export default config;
