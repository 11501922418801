
import { Mixins, Component, Ref } from "vue-property-decorator";
import { onResize, onView, onScroll } from "_mixins";
import { ShaderCanvas } from "shader-canvas";
import { shader } from "./lib";

@Component({
  name: "FooterWebGL",
})
export default class FooterWebGL extends Mixins(onResize, onView, onScroll) {
  rootClass = "c-footer-webgl";
  @Ref() canvas: HTMLElement;
  @Ref() plane: HTMLElement;
  runAnim = false;
  shaderEl = null;
  animReq: number | null = null; // Manage the animation request ID
  customTime = 0; // Custom time counter
  timeIncrement = 60; // Increment per frame (approx. 1/60th of a second)
  mounted() {
    this.init();
  }

  // Use onScroll for raf
  onScroll() {
    if (this.isInView && this.runAnim) {
      this.customTime += this.timeIncrement;
      this.shaderEl.setUniform("time", this.customTime / 1000);
      this.shaderEl.render();
    }
  }

  // onView(inView: boolean) {
  //   if (inView) {
  //     this.startAnimation();
  //   } else {
  //     this.stopAnimation();
  //   }
  // }

  onResize() {
    const bounds = this.canvas.getBoundingClientRect();
    this.shaderEl.setSize(bounds.width, bounds.height);
  }

  init() {
    const bounds = this.canvas.getBoundingClientRect();

    this.shaderEl = new ShaderCanvas();
    this.shaderEl.setShader(shader);
    this.shaderEl.setSize(bounds.width, bounds.height);
    this.shaderEl.setUniform("resolution", this.shaderEl.getResolution());
    this.shaderEl.setUniform("time", 0);
    this.canvas.appendChild(this.shaderEl.domElement);

    this.startAnimation();
  }

  startAnimation() {
    this.runAnim = true;
    // if (this.animReq !== null) return; // If animation is already running, do nothing

    // const animate = (timestamp) => {
    //   this.animReq = window.requestAnimationFrame(animate);
    //   // this.shaderEl.setUniform("time", timestamp / 1000);
    //   // this.shaderEl.render();
    //   // console.log(timestamp);
    // };
    // this.animReq = window.requestAnimationFrame(animate);
  }

  stopAnimation() {
    this.runAnim = false;

    // if (this.animReq !== null) {
    //   window.cancelAnimationFrame(this.animReq);
    //   this.animReq = null;
    // }
  }

  beforeDestroy() {
    this.stopAnimation();

    if (this.shaderEl && this.shaderEl.domElement) {
      this.canvas.removeChild(this.shaderEl.domElement);
      this.shaderEl = null; // Release the ShaderCanvas instance for garbage collection
    }
  }
}
